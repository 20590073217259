import React from "react";
import SkillsButton from "./SkillsButton";
import ProgramButton from "./ProgramButton";


function OperationsAnalyst() {
    return (
        <div>
            <div>
                <div className="grid-container work-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>Operations Analyst <span className="text-body-secondary">- ACHEM Industries</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 className="center year">2022</h5></div>
                    <div className="item3">
                        <p>Focused on database management, creating insightful reports, and optimizing SQL queries to meet team requirements.</p>
                    </div>
                    <div className="item4 skillsButton col-md-12">
                        <ProgramButton color="yellow" textColor="black" input="Business Analytics" />
                        <SkillsButton color="yellow" input="SQL" />
                        <ProgramButton color="green" textColor="black" input="Excel Macro" />
                        <ProgramButton color="popyellow" textColor='black' input="SAP" />
                        <ProgramButton color="popyellow" textColor='black' input="Salesforce" />



                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperationsAnalyst;