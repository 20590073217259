import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import NetworkFeatures from "./NetworkFeatures";
import InsertIcon from "../Misc_Parts/InsertIcon";


function NetworkServices() {
  const [dropDownActivated, setDropDown] = useState(false);

  function handleClick(event) {
    setDropDown(!dropDownActivated);
    event.preventDefault();
  }


  return (
    <div className="container px-4 py-3" id="icon-grid">
      <div><h3 onClick={handleClick} className="pb-2 border-bottom">Network & IT Engineering {dropDownActivated ? <InsertIcon icon="expand_circle_up" /> : <InsertIcon icon="expand_circle_down" />}</h3></div>
      <AnimatePresence mode="wait">
      {dropDownActivated && 
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
      <NetworkFeatures />
      </motion.div>
      }</AnimatePresence>
    </div>
  );
};

export default NetworkServices;


