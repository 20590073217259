import React from "react";
import ADA from "./Misc_Parts/ADA-Writeup";

function Accessibility() {
  return (
    <ADA />
  );
}

export default Accessibility;
