import React from "react";

function AboutBlog() {
    return (
        <div className="p-4 mb-3 bg-gradient rounded border-warning about-blog">
            <h4 className="fst-italic blog-title">Tito's Blog</h4>
            <p className="mb-0">Delving into web development, computer networking, and cryptocurrency, this blog offers technical articles, research, and analysis to fuel your expertise in these fields.</p>
        </div>
    );
};

export default AboutBlog;