import React from "react";
import FooterComponent from "./Misc_Parts/Footer_Component";

function Footer() {
  return (
    <FooterComponent />
  );
}

export default Footer;
