import React from "react";
import SkillsButton from "../../../About_Page/SkillsButton"
 
function CryptoSummaryMay19() {
    return (    <div>

                <article className="blog-post">
                    <h2 className='display-5 link-body-emphasis mb-1'>Weekly Analysis: Dip Concludes, Growth on the Horizon</h2>
                    <p style={{fontStyle:'italic'}}>May 19, 2024</p>
                    <p>As we navigate through the week, we’ve observed a slow recovery in the cryptocurrency market, indicating that the dipping phase has likely ended, as prognosticated last week. The current slow and steady growth is expected to persist for around two months before we see the exponential growth pattern observed in the previous two cycles.</p>
                    <br />
                    <h2>Crypto Highlights</h2>
                    <p>This week, we witnessed a recovery in many cryptocurrencies, particularly Bitcoin (BTC) and Solana (SOL). This signals the end of the dipping phase. Surprisingly, Ethereum (ETH) did not perform as well as BTC and SOL, possibly due to a decline in Ethereum miners and the anticipation that the US SEC might deny the Ether ETF this month.</p>
                    <p>Reports indicate that the Bitcoin supply appears to be dwindling, with an increasing rate of BTC being moved outside of centralized exchanges. Long-term holding of BTC is becoming more common compared to short-term trading among holders.</p>                    
                    
                    <br />
                    <h2>Crypto Trend</h2>
                    <p>Next, we will predict the BTC trend for the upcoming months by comparing it with BTC data from 2016.</p>

                    <div className='text-center'>
                    <img title='Figure 1: BTC 2016 Chart' src='/images/blog_pictures/202405/BTC_2016_TREND_05192024.png' alt='BTC 2016 Chart' width='90%'></img>
                    <p style={{fontStyle:'italic'}}>Figure 1: BTC 2016 Chart</p>

                    <img title='Figure 2: BTC 2024 Prediction Chart' src='/images/blog_pictures/202405/BTC_PREDICTION_05192024.png' alt='BTC 2024 Prediction Chart' width='90%'></img>
                    <p style={{fontStyle:'italic'}}>Figure 2: BTC 2024 Prediction Chart</p>
                    </div>
                    <p>As show in Figure 2, we are currently in the 'Gradual Recovery Phase,' during which we will see slow and steady growth for the next 2-3 months before entering the 'Uplift Phase.' BTC is expected to hit above $70,000 before the month ends. </p>
                    <br />
                    <h2>Random Roaring Kitty's tweet, putting stocks on heat</h2>
                    <p>A previously banned investment influencer - <span style={{fontStyle:'italic'}}>Roaring Kitty</span> - resurfaced on social media, causing meme stocks and their related coins to experience a roller coaster thrill over the week. Meme stocks like Gamestop and AMC saw unexpected activity reminiscent of the events of 2021.</p>
                    <p>This resurgence signals that many players have received the call to return to the cryptocurrency market, suggesting that the 'Uplift Phase' is approaching. Investors should remain vigilant and pay close attention to spontaneous events that could potentially impact the prices of stocks and meme coins.</p>

                    <br />


                    <h2>Overall Economic Performance</h2>
                    <p>Recent data indicates that despite officials denying it, the US economy still faces a recession this quarter. Sales of big-ticket items and restaurant spending have fallen compared to previous quarters, and defaults on loans such as credit cards are rising.</p>
                    <p>Tech companies continue to lay off employees (for example, Tesla dismissing their entire Supercharger team) without creating more technical jobs to fill the void. This could eventually lead to a negative multiplier effect, impacting other industries such as F&B, hospitality, and supermarkets, which provide goods and services to households previously employed in tech.</p>
                    <p>Despite the economic downturn, the anticipation of interest rate cuts over the next few months is growing stronger, which could further boost crypto prices. To support this claim, inflation calculations were recently re-evaluated. High inflation has been a limiting factor for adjusting interest rates.</p>
                    <br />

                    <h2>Inflation</h2>
                    <p>We continue to see cost-push inflation nationwide, with the Federal Reserve providing little explanation for excluding factors such as coffee prices when calculating the inflation index. Inflation scores are likely higher than reported, as food prices remain high due to the recent avian flu outbreak and persistently high rent prices. These manipulated scores make inflation figures more controllable, potentially allowing the committee to justify rate cuts. Despite officials' attempts to downplay inflation, it remains a significant issue that needs to be addressed, as it hurts the economy.</p>
                    <br />

                    <h2>Investment Consideration</h2>
                    <p>With the dip period ending, the 'Uplift' period is approaching. Before their prices start to soar, now is an opportune time to invest in cryptocurrency, particularly in Layer 1 coins and stablecoins such as BTC and SOL.</p>

                    <br />
                    <p>Tag: <SkillsButton color="purple" input="Cryptocurrency" /> <SkillsButton color="yellow" input="Economy" /></p>
                </article> </div>
    );
};
 
export default CryptoSummaryMay19;