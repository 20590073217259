import React from "react";
import WebDevelopment from "./WebDevelopment";

function PastProjects() {
    return (
        <div>
            <div className="container">
                <div>
                    <h3>Past Projects</h3>
                </div>
                <div>
                    <WebDevelopment />
                </div>
            </div>
        </div>
    );
};

export default PastProjects;