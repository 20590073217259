import React from "react";
import SkillsButton from "./SkillsButton";
import { ExternalLink } from "react-external-link";


function DocIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-check" viewBox="0 0 16 16">
            <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
        </svg>);
}

function UdemyFullStack() {
    return (
        <div>
            <div>
                <div className="grid-container school-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>The Complete 2024 Web Development Bootcamp <span className="text-body-secondary">- Udemy</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 className="center year">2023 - 2024</h5></div>
                    <div className="item3">
                        <p>Completed Full-Stack Web Development course, covering extensive Front-End and Back-End development techniques, alongside an introduction to Web3 Development. <br />
                        <span style={{fontWeight:"bold"}}> Certification:  <ExternalLink href="https://www.udemy.com/certificate/UC-a1726442-955a-4804-ac4d-8f1aba013a26/" aria-label="View certification for The Complete 2024 Web Development Bootcamp on Udemy"><DocIcon /></ExternalLink> </span>
                        </p>
                    </div>
                    <div className="item4 col-xl-12 skillsButton  col-12">
                        <SkillsButton color="info" input="Javascript(ES6)" />
                        <SkillsButton color="info" input="React.js" />
                        <SkillsButton color="info" input="Node.js" />
                        <SkillsButton color="info" input="Express.js" />
                        <SkillsButton color="info" input="jQuery" />
                        <SkillsButton color="info" input="Embedded JS" />
                        <SkillsButton color="yellow" input="PostgreSQL" />
                        <SkillsButton color="yellow" input="Encryption" />
                        <SkillsButton color="yellow" input="Authentication" />
                        <SkillsButton color="purple" input="Web3" />
                        <SkillsButton color="purple" input="Motoko" />
                        <SkillsButton color="green" input="Bootstrap" />
                        <SkillsButton color="green" input="HTML" />
                        <SkillsButton color="green" input="CSS" />
                    </div>
                </div>
            </div>
            <div>
                <div className="grid-container school-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>Understanding TypeScript <span className="text-body-secondary">- Udemy</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 style={{ display: 'none' }} className="center year">2023 - 2024</h5></div>
                    <div className="item3">
                        <p>Enhanced back-end skills, focusing on integrating TypeScript's advanced features, such as types, ES6 support, and interfaces, into real-world ReactJS and NodeJS/Express projects. <br />
                        <span style={{fontWeight:"bold"}}> Certification: <ExternalLink href="https://www.udemy.com/certificate/UC-79fa22d8-d6ac-4fec-96db-221ef9b9bfb8/" aria-label="View certification for Understanding Typescript on Udemy"><DocIcon /></ExternalLink> </span>
                        </p>
                    </div>
                    <div className="item4 col-xl-12 skillsButton  col-12">
                        <SkillsButton color="blue" input="TypeScript" />
                        <SkillsButton color="info" input="Javascript" />
                        <SkillsButton color="info" input="React.js" />
                        <SkillsButton color="info" input="Node.js" />
                        <SkillsButton color="info" input="Express.js" />
                    </div>
                </div>
            </div>







        </div>
    );

};

export default UdemyFullStack;