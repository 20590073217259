import React from "react";
import UdemyFullStack from "./Udemy-Fullstack";
import CSUF from "./CSUF";

function Education() {
    return (
        <div>
            <div className="container">
                <div>
                    <h3>Education</h3>
                </div>
                <CSUF />
                <UdemyFullStack />
            </div>
        </div>
    );
};

export default Education;