import React from "react";
 
function Seagull404(props) {
    var setWidth = 400;
    if (props.width) {
        setWidth = props.width;
    }


    return (
        <div>
        <img src="images/bird404.svg" width={setWidth} style={{marginTop:"-5rem", marginBottom:"-5rem"}} alt="drawing of a cartoon seagull looking confused" />
    </div>
    );
};
 
export default Seagull404;