import React from "react";
import { ExternalLink } from "react-external-link";
 
function ExternalSites() {
    return (
        <div hidden="true" className="p-4">
  <h4 className="fst-italic">External Sites</h4>
  <ol style={{display:"none"}} className="list-unstyled">
    <li><ExternalLink style={{ textDecoration: "none", color: "black" }} href="#"><img style={{ width: "20px", height:"20px" }} className="arrow-icon" src="images/External_Link_Arrow.svg" alt="Arrow Icon" />GitHub</ExternalLink></li>
    <li><ExternalLink style={{ textDecoration: "none", color: "black" }} href="#"><img style={{ width: "20px", height:"20px" }} className="arrow-icon" src="images/External_Link_Arrow.svg" alt="Arrow Icon" />Twitter</ExternalLink></li>
    <li><ExternalLink style={{ textDecoration: "none", color: "black" }} href="#"><img style={{ width: "20px", height:"20px" }} className="arrow-icon" src="images/External_Link_Arrow.svg" alt="Arrow Icon" />Facebook</ExternalLink></li>
  </ol>
</div>

    );
};
 
export default ExternalSites;