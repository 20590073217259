import React from "react";
import CryptoSummaryMay19 from "./Posts/2024_05/CryptoSummaryMay19"
import CryptoSummaryMay11 from "./Posts/2024_05/CryptoSummaryMay11"
import AbruptMondayMay21 from "./Posts/2024_05/AbruptMondayMay21"

function BlogPosts() {
    return (<div>
        <AbruptMondayMay21 />
        <br />
        <CryptoSummaryMay19 />
        <br />
        <CryptoSummaryMay11 />
        
    </div>
    );
};

export default BlogPosts;