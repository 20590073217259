import React from "react";
import NavBar from "./Misc_Parts/Nav_Bar";

function Header() {
  return (
      <NavBar />
  );
}

export default Header;
