import React from "react";
import IconCreator from "./IconCreator";

function FeatureCard(props) {
  var extraClass = "";
    if (props.color === "bg-light" || props.color === "bg-grey"  || props.color === "bg-yellow" || props.color === "bg-green" ){
      extraClass = "text-bg-light";
    } else {
      extraClass = "text-bg-primary";
    }

    //const imgPath = props.img;
    return (
        <div className="col d-flex flex-column gap-2">
          <div className={`feature-icon-small d-inline-flex align-items-center justify-content-center bg-gradient fs-4 rounded-3 ${props.color} ${extraClass}`}>
            <IconCreator svg={props.svg}/>   
          </div>
          <h4 className="fw-semibold mb-0 text-body-emphasis feature-card-title">{props.title}</h4>
          <p className="text-body-secondary">{props.text}</p>
        </div>
    );
};
 
export default FeatureCard;