import React from "react";
import Seagull404 from "./Seagull404";
import { Link } from "react-router-dom";

function Error404() {
    return (
        <div className="container">
            <div>
                <h1>
                    ERROR 404.
                </h1>
            </div>
            <div className="row">
                <div className="col-xl-6 cpl-sm-12">
                    <div className="center">
                        <Seagull404 />
                    </div>
                    <div>
                        <div className="center">
                            <p>Ooops! Something's wrong???!!!</p>
                            <p>It appears that the page you are searching for does not exist.</p>
                            <br />
                        </div>
                        <div className="center">
                            <Link to="/"><button style={{ padding: '20px 80px', fontSize:"1.5rem", fontFamily:`"Arvo", serif`,fontWeight:"500" }} className="btn btn-pink bg-gradient" type="button">{'   '}Take Me Back Home!{'   '}</button></Link></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error404;