import React from "react";
import { ExternalLink } from "react-external-link";

function WebsiteCard(props) {


    return (
        <div className="col card-group">
            <div className="card shadow-sm">
                <img src={props.img} alt={props.alt} className="bd-placeholder-img card-img-top" width="100%" height="100%"  preserveAspectRatio="xMidYMid slice" focusable="false" />
                <div className="card-body">
                    <h5 className="card-text center">{props.name} {props.type}</h5>
                    <p className="card-text">{props.text}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm btn-outline-secondary">
                            <ExternalLink href={props.url}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                    <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                </svg>
                                </ExternalLink>
                            </button>
                        </div>
                        <small className="text-body-secondary">{props.year}</small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebsiteCard;