import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, speed, type }) => {
  var ifTypeExist = false;
  if (type){
    ifTypeExist = true;
  }


  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, speed);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, speed, text]);

  return (ifTypeExist?  <h1><span style={{display:"inline-block",color:"#212529"}}>{currentText}</span></h1>   : <p className="lead"><span style={{display:"inline-block",color:"#212529"}}>{currentText}</span></p> );
};

export default Typewriter;