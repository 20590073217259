import React from "react";

function ServiceWriteUp() {
    return (
        <div className="container">
            <div>
                <h1>SERVICES</h1>
            </div>
            <hr className="featurette-divider" />
            <div>
                <h2>Here are the services that I provide:</h2>
                <br />
                <p>Clients can request on-site calls exclusively in the Orange County & surrounding areas.</p>
                <p>I'm proficient in Mandarin Chinese, also providing services utilizing this language.  <br />
                Additionally, I can offer assistance in Japanese and Indonesian, though my proficiency in these languages is limited with reading and writing.</p>
            </div>
        </div>
    );
};

export default ServiceWriteUp;