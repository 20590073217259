import React from "react";

function SkillsButton(props) {
    // if (props.color === "cyan") {   //Web2 Lang or javascript related
    //     return (
    //         <span className="badge bg-info-subtle text-info-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "green") { //HTMLCSS related
    //     return (
    //         <span className="badge bg-success-subtle text-success-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "red") { 
    //     return (
    //         <span className="badge bg-danger-subtle text-danger-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "yellow") { //Database Related
    //     return (
    //         <span className="badge bg-warning-subtle text-warning-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "grey") {
    //     return (
    //         <span className="badge bg-light-subtle text-light-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "dark") { //OS-related maybe
    //     return (
    //         <span className="badge bg-dark-subtle text-dark-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "purple") { //Web3 Related
    //     return (
    //         <span className="badge bg-primary-subtle text-primary-emphasis rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "dull") { 
    //     return (
    //         <span className="badge bg-secondary-subtle text-secondary-emphasis rounded-pill">{props.input}</span>
    //     );
    // };


    const input = props.input;
    const color = props.color;
    const newClass1 = "bg-"+color+"-subtle";
    const newClass2 = "text-"+color+"-emphasis";
    
    return (
             <span className={`badge ${newClass1} ${newClass2} bg-gradient  rounded-pill`}>{input}</span>
        ); 

}



export default SkillsButton;