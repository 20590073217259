import React from "react";
import Profile from "./About_Page/Profile";
import Background from "./About_Page/Background";


function About() {
  return (
    <div>
    <Profile />
    <Background />
    </div>
  );
}

export default About;
