import React from "react";
import SkillsButton from "./SkillsButton";
import ProgramButton from "./ProgramButton";

function CSUF() {
    return (
        <div>
            <div>
                <div className="grid-container school-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>BS Computer Science <span className="text-body-secondary">- California State University, Fullerton</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 className="center year">2017 - 2021</h5></div>
                    <div className="item3">
                        <p>Minor in Mathematics</p>
                    </div>
                    <div className="item4 skillsButton  col-md-12">
                        <SkillsButton color="navy" input="C++" />
                        <SkillsButton color="navy" input="C" />
                        <SkillsButton color="navy" input="Python" />
                        <SkillsButton color="yellow" input="SQL" />
                        <SkillsButton color="yellow" input="PHP" />
                        <SkillsButton color="beige" input="Matlab" />
                        <SkillsButton color="beige" input="R" />
                        <SkillsButton color="green" input="HTML" />
                        <SkillsButton color="green" input="CSS" />
                        <SkillsButton color="dark" input="Linux" />
                        <SkillsButton color="dark" input="x86" />
                        <ProgramButton color="pink" input="Malware Analysis" />
                        <ProgramButton color="blue" input="Wireshark" />
                    </div>
                </div>
            </div>
            <div>
                <div className="grid-container school-details">
                    <div className="item1"><h6>BA Economics <span className="text-body-secondary">- California State University, Fullerton</span></h6></div>
                    <div className="item2"><h6 style={{ display: 'none' }}>2017-2021</h6></div>
                    <div className="item3">
                        <p>Minor in Asian Studies</p>
                    </div>
                    <div className="item4 skillsButton">
                        <SkillsButton color="beige" input="R" />
                        <ProgramButton color="popyellow" textColor="black" input="Rguroo" />
                        <ProgramButton color="yellow" textColor="black" input="Mathematical Economics" />
                        <ProgramButton color="yellow" textColor="black" input="Business Analytics" />
                        <ProgramButton color="yellow" textColor="black" input="International Trade & Macroeconomics" />
                        <ProgramButton color="forestgreen" input="Mandarin Chinese" />
                        <ProgramButton color="forestgreen" input="Japanese" />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default CSUF;