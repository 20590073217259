import React from "react";

function ProgramButton(props) {
    // if (props.color === "cyan") {    // server related
    //     return (
    //         <span className="badge text-bg-info rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "green") {  // language related
    //     return (
    //         <span className="badge text-bg-success rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "red") {  
    //     return (
    //         <span className="badge text-bg-danger rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "yellow") { //econ prrogram
    //     return (
    //         <span className="badge text-bg-warning rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "light") { //malware related
    //     return (
    //         <span className="badge text-bg-light rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "dark") { //theory related (econs)
    //     return (
    //         <span className="badge text-bg-dark rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "blue") {  //CPSC program
    //     return (
    //         <span className="badge text-bg-primary rounded-pill">{props.input}</span>
    //     );
    // } else if (props.color === "grey") { 
    //     return (
    //         <span className="badge text-bg-secondary rounded-pill">{props.input}</span>
    //     ); 
    // };
    const input = props.input;
    const color = props.color;
    var textColor = "text-white";
    if (props.textColor) {
        textColor = "text-black";
    }
    const newClass = "bg-"+color;
    
    return (
             <span className={`badge ${newClass} ${textColor} bg-gradient rounded-pill`}>{input}</span>
        ); 

}



export default ProgramButton;