import React from "react";
import FeatureCard from "./FeatureCard";
 
function NetworkFeatures() {
    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5 services-features network-features">
        <FeatureCard title="Network Setup" svg="lan" color="bg-navy" text="Do you want your enterprise network to run smoothly without any disruptions?" />
        <FeatureCard title="Servers Setup" svg ="router" color="bg-navy" text="Looking to scale up your operations with additional servers? Whether it's in your office or on the cloud, we've got you covered." />
        <FeatureCard title="System Repair & Maintenance" svg ="build_circle" color="bg-navy" text="Even computers need some love and attention—they're like our digital babies, deserving of a little pampering too!" />
        <FeatureCard title="Firewall Setup" svg="local_fire_department" color="bg-pink" text="Concerned about safeguarding your office network? Let's set up a robust firewall for you." />
        <FeatureCard title="Database Migration & Management" svg="database" color="bg-green" text="Dealing with a single Google Sheet that's overloaded with 5GB of data? It's time to consider migrating your database." />
        <FeatureCard title="Macros Setup" svg="screen_record" color="bg-beige" text="Tired of hearing excuses about the OL's freshly done nails? It might be time to automate tasks with macros and give her a break from the keyboard." />
        <FeatureCard title="Domain & Email Setup" svg="alternate_email" color="bg-grey" text="Starting a new business and want to skip the technical headaches?" />
        <FeatureCard title="Software Coaching" svg="school" color="bg-grey" text="Transitioning to new technology can be challenging, and providing extra support to your team can be instrumental in helping them leverage new software to enhance productivity." />
        </div>
    );
};
 
export default NetworkFeatures;