import React from "react";
import AboutBlog from "./AboutBlog";
import ExternalSites from "./ExternalSites";
 
function SideBar() {
    return (
        <div className="col-md-4">
          <div className="position-sticky" style={{ top: '2rem' }}>
            <AboutBlog />
            
            <ExternalSites />
          </div>
        </div>
      );
    }

 
export default SideBar;