import React from "react";
// eslint-disable-next-line no-unused-vars
import { MaterialSymbol } from "material-symbols";


function InsertIcon(props) {
    return (<span title={props.title} alt={`${props.icon} icon`} className="material-symbols-rounded">
        {props.icon}
    </span>);
}


export default InsertIcon;