import React from "react";
import FeatureCard from "./FeatureCard";
 
function SoftwareFeatures() {
    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5 services-features software-features">
        <FeatureCard title="Front-End Development" svg="developer_mode_tv" color="bg-forestgreen" text="Looking to launch a stunning new website? Let me bring your vision to life." />
        <FeatureCard title="Back-End Development" svg ="dvr" color="bg-maroon" text="Ready to elevate your application with new features? Count on me to make it happen." />
        <FeatureCard title="Web3 Development" svg ="currency_bitcoin" color="bg-purple" text="Interested in a Decentralized App (DApp)? I've got you covered." />
        <FeatureCard title="Smart Contract Deployment" svg ="contract_edit" color="bg-purple" text="Seeking assistance with reviewing your smart contract before deployment?" />
        <FeatureCard title="Asian-Languages Integration" svg ="language_chinese_dayi"  color="bg-yellow" text="Looking to expand your customer base to a wider market?"  />
        <FeatureCard title="Automation & Bots Setup" svg ="smart_toy"  color="bg-dark" text="Need tasks to run seamlessly even while you're sleeping? I've got you covered."  />
        </div>
    );
};
 
export default SoftwareFeatures;