import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Typewriter from "./Typewriter";
import DelayedTypewriter from "./DelayedTypeWriter";

function HeroSection() {
  return (
    <div className="">
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column Hero-Container">
        <div style={{ display: "inline-block", height: "200px", zIndex: "-2" }} className="container"></div>
        <main className="px-3 center">
          <br />
          <br />
          <div><Typewriter text="Hi, I'm TitoP." speed={70} type="h1" /></div>
          <br />
          <br />
          <div>
            <DelayedTypewriter text="I am a Full-Stack Engineer with a fervent passion for technology. " speed={28} wait={1100} />
          </div>
          <br />
          <motion.div
            animate={{ scale: 1, }}
            initial={{ scale: 0, }}
            transition={{ delay: 3.4 }}
          >
            <p>
              <Link to="/About" className="btn btn-lg btn-babyblue text-black fw-bold border-info bg-gradient">Learn more</Link>
            </p>
          </motion.div>
          <br /><br /><br />

          <div>
            <DelayedTypewriter text="Accelerate your app's development with me today." speed={30} wait={3500} />
          </div>

          <br />
          <motion.div
            animate={{ scale: 1, }}
            initial={{ scale: 0, }}
            transition={{ delay: 5.6 }}
          >
            <p className="lead">
              <Link to="/services" className="btn btn-lg btn-pink fw-bold border-danger bg-gradient">Find out more</Link>
            </p>
          </motion.div>
          <br /><br /><br />
        </main>
        <div style={{ display: "inline-block", height: "200px" }} className="container"></div>

      </div></div>
  );
};

export default HeroSection;