import React from "react";

function Pagination() {
    return (
                //{/* Pagination */}
                // <nav className="blog-pagination" aria-label="Pagination">
                //     <a className="btn btn-outline-primary rounded-pill" href="#">Older</a>
                //     <a className="btn btn-outline-secondary rounded-pill disabled" href="#" tabIndex="-1" aria-disabled="true">Newer</a>
                // </nav> 
                <div></div>
    );
};

export default Pagination;