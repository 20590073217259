import React from "react";
import IconComponent from "./Icon-Component";
import { Link } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';

 
function NavBar(){
    return (
        <div className="container navibar">
          <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
            <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none" aria-label="Home">
              <IconComponent  
                width="40" 
                height="32"
              />
              <span style={{fontWeight:"700", color:"#212529"}} className="fs-4">TitoP</span>
            </Link>
    
            <ul className="nav nav-pills">
{/*               <li className="nav-item"><a href="/" className="nav-link active" aria-current="page">Home</a></li> */}
              <li className="nav-item"><Link to="/about"  style={{color:"#212529"}} className="nav-link">About</Link></li>
              <li className="nav-item"><Link to="/services" style={{color:"#212529"}} className="nav-link">Services</Link></li>
              <li style={{ display: "none" }} className="nav-item"><Link to="/blog" style={{color:"#212529"}} className="nav-link">Blog</Link></li>
              <li className="nav-item"><Link to="/contact" style={{color:"#212529"}} className="nav-link">Contact</Link></li>
            </ul>
          </header>
        </div>
      );
};
 
export default NavBar;