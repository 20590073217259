import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SoftwareFeatures from "./SoftwareFeatures";
import InsertIcon from "../Misc_Parts/InsertIcon";

function SoftwareDevelopment() {
  const [dropDownActivated, setDropDown] = useState(false);

  function handleClick(event) {
    setDropDown(!dropDownActivated);
    event.preventDefault();
  }

  return (
    <div className="container px-4 py-4" id="icon-grid">
      <div>
        <h3 onClick={handleClick} className="pb-2 border-bottom">Software Engineering {dropDownActivated ? <InsertIcon icon="expand_circle_up" /> : <InsertIcon icon="expand_circle_down" />}</h3>
      </div>
      <AnimatePresence mode="wait">
      {dropDownActivated && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2 }}
        >
          <SoftwareFeatures />
        </motion.div>
      )}</AnimatePresence>
    </div>
  );
}


export default SoftwareDevelopment;