import React, { useState, useEffect } from 'react';
import Typewriter from "./Typewriter"; // Make sure to import Typewriter correctly

function DelayedTypewriter(props) {
  const [showTypewriter, setShowTypewriter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTypewriter(true);
    }, props.wait); // Delay of 1000ms

    return () => clearTimeout(timer); // Cleanup the timer
  }, [props.wait]); // Empty dependency array means this effect runs only once after the initial render

  return (
    <>
     {showTypewriter && <Typewriter text={props.text} speed={props.speed} />}</>
  );
}

export default DelayedTypewriter;
