import React from "react";
import WebsiteCard from "./WebsiteCard";

function WebDevelopment() {
    return (
        <div className="container " >
            <div  className="album py-3  past-project">
                <div className="container">
                <h4>Web Development</h4>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-2">
                        <WebsiteCard name="Sunvalleytek" year="2022" type="Website" alt="sunvalley_website_screenshot" url="https://www.sunvalleytek.com/"  img='images/sunvalley_website.png' text="Revamping the website with UI/UX designers, focusing on translating intricate designs into functional, user-friendly code to match the new leadership's vision."/>
                        <WebsiteCard name="VAVA" year="2022" type="Shopify Website" alt="vava_website_screenshot" url="https://www.vava.com/" img='images/vava_website.png' text="Collaborating with designers to launch landing pages for new products, maintaining the website, addressing bugs, and ensuring ADA compliance."/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebDevelopment;


