import React from "react";
import SideBar from "./SideBar";
import Pagination from "./Pagination";
import BlogPosts from "./BlogPosts";

function BlogArea() {
    return (
        <div className="row g-5">
            <div className="col-md-8">
                <h3 className="pb-4 mb-4 fst-italic border-bottom">
                    Written by TitoP.
                </h3>
                <BlogPosts />
                <Pagination />
            </div>
            <SideBar />
        </div>
    );
};

export default BlogArea;