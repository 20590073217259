import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import PastProjects from "./PastProjects";
import InsertIcon from "../Misc_Parts/InsertIcon";


function Background() {
    /*     const [eduActivated, setEduActivated] = useState(true);
        const [workActivated, setWorkActivated] = useState(false);
        const [projectsActivated, setProjectsActivated] = useState(false);
    
        function activateButton(selectedButton) {
            switch (selectedButton) {
                case 'education':
                    setEduActivated(true);
                    setWorkActivated(false);
                    setProjectsActivated(false);
                    break;
                case 'work':
                    setWorkActivated(true);
                    setEduActivated(false);
                    setProjectsActivated(false);
                    break;
                case 'projects':
                    setProjectsActivated(true);
                    setEduActivated(false);
                    setWorkActivated(false);
                    break;
                default:
                    console.log(selectedButton);
                    break;
            }
        } */

    const [activeComponent, setActiveComponent] = useState('education');

/*     function handleClick(event) {
        //console.log(event.target);
        const selectedButton = event.target.title;
        setActiveComponent(selectedButton);
        event.preventDefault();
    } */

    return (
        <div className="container">
            <div>
                <h1>Background</h1>
            </div>
            <div className="container background-container row py-3">
                <div className="background-buttons col-xl-9 col-sm-12 col-12">
                    <div title="education">
                        <button onClick={() => setActiveComponent('education')} className={`btn bg-gradient d-inline-flex align-items-center bg-button ${activeComponent === 'education' ? 'btn-yellow' : 'btn-dark'}`} type="button">
                            Education<InsertIcon icon="school" />
                        </button>
                    </div>
                    <div title="work">
                        <button onClick={() => setActiveComponent('work')} className={`btn bg-gradient d-inline-flex align-items-center bg-button ${activeComponent === 'work' ? 'btn-navy' : 'btn-dark'}`} type="button">
                            Work<InsertIcon icon="work" />
                        </button>
                    </div>
                    <div title="projects">
                        <button onClick={() => setActiveComponent('projects')} className={`btn bg-gradient d-inline-flex align-items-center bg-button ${activeComponent === 'projects' ? 'btn-beige' : 'btn-dark'}`} type="button">
                            Projects<InsertIcon icon="design_services" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="py-3">
                <AnimatePresence mode="wait">
                    {activeComponent === 'education' && (
                        <motion.div key="education" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                            <Education />
                        </motion.div>
                    )}
                    {activeComponent === 'work' && (
                        <motion.div key="work" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}  transition={{ duration: 0.2 }}>
                            <WorkExperience />
                        </motion.div>
                    )}
                    {activeComponent === 'projects' && (
                        <motion.div key="projects" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}  transition={{ duration: 0.2 }}>
                            <PastProjects />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Background;