import React from "react";
import ServiceWriteUp from "./Services_Page/ServiceWriteUp";
import NetworkServices from "./Services_Page/Network-Services";
import SoftwareDevelopment from "./Services_Page/SoftwareDevelopment";
import ContactMeButton from "./Services_Page/ContactMeButton";



function Services() {
  return (
    <div className="container">
    <div>
      <ServiceWriteUp />
      <div className="container">
      <SoftwareDevelopment />
      <NetworkServices />
      </div>
      <br />
      <div className="container center">
        <ContactMeButton />
      </div>
      <br />
    </div>
    </div>
  );
}

export default Services;
