import React from "react";
import Freelance from "./Freelance";
import OperationsAnalyst from "./OperationsAnalyst"
import ITEngineer from "./ITEngineer"

function WorkExperience() {
    return (
        <div>
            <div className="container">
                <div>
                    <h3>Work Experience</h3>
                </div>
                <Freelance />
                <OperationsAnalyst />
                <ITEngineer />
            </div>
        </div>
    );
};

export default WorkExperience;