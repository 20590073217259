import React from "react";
import IconComponent from "./Icon-Component";
import { Link } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';

function FooterComponent() {
  const year = new Date().getFullYear();
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <p style={{fontStyle:"italic"}} className="col-md-4 mb-0 footer-text">ⓒ {year}</p>
        <Link to="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none" aria-label="Home">
          <IconComponent
            width="40"
            height="32"
          />
        </Link>

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item"><Link to="/about"  style={{color:"#1c5b76"}} className="nav-link px-2 ">About</Link></li>
          <li className="nav-item"><Link to="/services"  style={{color:"#1c5b76"}} className="nav-link px-2 ">Services</Link></li>
          <li style={{ display: "none" }} className="nav-item"><Link to="/blog"  style={{color:"#1c5b76"}} className="nav-link px-2 y">Blog</Link></li>
          <li className="nav-item"><Link to="/contact"  style={{color:"#1c5b76"}} className="nav-link px-2 ">Contact</Link></li>
          <li className="nav-item"><Link to="/accessibility"  style={{color:"#1c5b76"}} className="nav-link px-2 ">Accessibility</Link></li>
        </ul>
      </footer>
    </div>
  );
};

export default FooterComponent;