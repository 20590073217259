import React from "react";

function Profile() {
    return (
        <div>
            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-md-7">
                        {/* <h2 className="featurette-heading fw-normal lh-1">About Me <span className="text-body-secondary"></span></h2> */}
                        <h1 className="featurette-heading">ABOUT ME</h1>
                        <br />
                        <p className="lead">Hi I am Titus Pahn, AKA TitoP.</p>
                        <br />
                        <p>I'm a Full-Stack and Network Engineer residing in Orange County, California. My fascination with technology was nurtured through experiences in different countries across Asia. My coding journey began in the third grade with a robotics summer camp, sparking a lifelong passion for programming.</p>
                        <br />
                        <p>Beyond the realm of coding and configuring servers, my leisure time is often spent in the company of my two beloved cats or indulging in the vibrant café culture around Los Angeles. Whether I'm debugging code or exploring the latest coffee spot, I'm constantly in pursuit of mastering my craft and savoring the simple joys of life.</p>
                        <br />
                    </div>
                    <div className="col-md-5">
                        <img className="profile-pic bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src='images/TitoP_Profile_Pic.png' alt="TitoP Profile Pic with his cats Anime Style" preserveAspectRatio="xMidYMid slice" focusable="false" />
                    </div>
                </div>

                <hr className="featurette-divider" />

            </div>
        </div>
    );
};

export default Profile;