import React from "react";
import { MaterialSymbol } from "material-symbols";

function IconCreator(props) {

    return (
        <span alt={props.svg}  className="bi material-symbols-rounded" width="2em" height="2em">
            {props.svg}
        </span>
    );
};

export default IconCreator;