import React from "react";
import { ExternalLink } from "react-external-link";


function ContactUs() {
    return (
        <div className="container ">
            <div>
                <div>
                    <h1>CONTACT</h1>
                </div>
                <hr className="featurette-divider" />
                <div className="row">
                    <div className="left-contact  col-lg-6">
                        <div className="">
                            <table>
                                <tbody>
                                    <tr className="row">
                                        <td className="col-md-6 col-12">
                                            <h5>EMAIL</h5>
                                        </td>
                                        <td className="col-md-6 col-12">
                                            <div>
                                                <div className="link">
                                                    <p className="Contact-info email-info"><ExternalLink className="text-decoration-none" href="mailto:tito@titop.tech" style={{ color: "#212529" }}> <img className="mail-icon" src="images/Email_Icon.svg" alt="Mail Icon" /> TITO@TITOP.TECH</ExternalLink></p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="right-contact  col-lg-6">
                        <div className="">
                            <table>
                                <tbody>
                                    <tr className="row">
                                        <td className="col-md-6 col-12">
                                            <h5 className="Social-media">SOCIAL MEDIA</h5>
                                        </td>
                                        <td className="col-md-6 col-12">
                                            <div>
                                                <div className="link">
                                                    <ul className="Social-media-list">
                                                        <li className="Contact-info email-info"><ExternalLink className="text-decoration-none" href="https://www.linkedin.com/in/titus-pahn/" style={{ color: "#212529" }}><img className="arrow-icon" src="images/External_Link_Arrow.svg" alt="Arrow Icon" />  LINKEDIN</ExternalLink></li>
                                                        <li style={{ display: 'none' }} className="Contact-info email-info"><ExternalLink className="text-decoration-none" href="https://github.com/titoP-kozuki" style={{ color: "#212529" }}><img className="arrow-icon" src="images/External_Link_Arrow.svg" alt="Arrow Icon" />  GITHUB</ExternalLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;