import React from "react";
import { ExternalLink } from "react-external-link";
 
function ADA(){
    return (
        <div className="container">
        <div>
            <h1>
                Accessibility
            </h1>
            <br /></div>
            <div>
            <h3>Commitment to Accessibility</h3>
            <p>Our dedication to accessibility is unwavering. We are committed to ensuring that our website and services are accessible to all, in compliance with the Americans with Disabilities Act (ADA). We believe everyone should have equal access to the resources and information available on our site.</p>
            <br />
            <p>Despite our efforts to make all pages and content on our website fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>
            <br />
            </div>
            <div>
            <h5>Here's How You Can Help:</h5>
            <p>If you encounter any difficulty using our website or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities, please email us at <ExternalLink href="mailto:tito@titop.tech">tito@titop.tech</ExternalLink> with the words "ADA" in the subject line. Provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement.</p>
            <br />
            <p>We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies. Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user-friendly.</p>
            <br />
            </div>
        </div>
    );
};
 
export default ADA;