import React from "react";
import { Link } from "react-router-dom";

function ContactMeButton() {
    return (
        <div><Link to="/contact">
            <button type="button" className="btn bg-gradient btn-beige text-black border-danger contact-button">Let's work together!</button></Link>
        </div>
    );
};

export default ContactMeButton;