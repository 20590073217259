import React from "react";
import ContactUs from "./Contact_Page/ContactUs";



function Contact() {
  return (
    <div>
        <ContactUs />
    </div>
  );
}

export default Contact;
