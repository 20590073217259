import React from "react";
import SkillsButton from "./SkillsButton";
import ProgramButton from "./ProgramButton";


function ITEngineer() {
    return (
        <div>
            <div>
                <div className="grid-container work-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>IT Engineer/Web Developer <span className="text-body-secondary">- Sunvalleytek International</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 className="center year">2021 - 2022</h5></div>
                    <div className="item3">
                        <p>Skilled in managing network infrastructure, cloud services, IT assets, and providing advanced helpdesk support, as well as maintaining and developing websites in collaboration with designers.</p>
                    </div>
                    <div className="item4 skillsButton col-md-12">
                        <SkillsButton color="info" input="Javascript" />
                        <SkillsButton color="green" input="HTML" />
                        <SkillsButton color="green" input="CSS" />
                        <ProgramButton color="forestgreen" input="Shopify" />
                        <ProgramButton color="forestgreen" input="Squarespace" />
                        <ProgramButton color="maroon" input="ADA Compliance" />
                        <SkillsButton color="yellow" input="SQL" />
                        <ProgramButton color="popyellow" textColor="black" input="Warehouse Management System" />
                        <ProgramButton color="green" textColor="black" input="Excel Macro" />
                        <ProgramButton color="blue" input="Unifi" />          
                        <ProgramButton color="blue" input="Synology" />    
                        <ProgramButton color="beige" input="VLAN" />
                        <ProgramButton color="beige" input="VPN" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ITEngineer;