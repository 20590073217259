import React from "react";
import HeroSection from "./Home_Page/HeroSection";

function Home() {
  return (
    <div>
    <HeroSection />
    </div>
  );
}

export default Home;
