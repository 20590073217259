import React from "react";
import SkillsButton from "../../../About_Page/SkillsButton";
import TweetElement from "../../../Misc_Parts/TweetElement"
 
function AbruptMondayMay21() {
    return (    <div>

                <article className="blog-post">
                    <h2 className='display-5 link-body-emphasis mb-1'>An Abrupt Monday: Sudden Positive Perception Towards Approval of Ethereum Spot ETF</h2>
                    <p style={{fontStyle:'italic'}}>May 21, 2024</p>
                    <h2>Ethereum</h2>
                    <p>Just as we mentioned on Sunday, Ethereum had not been performing as well as BTC and SOL due to the anticipated disapproval for the Ethereum Spot ETF. However, the general sentiment shifted positively following a tweet from Bloomberg’s senior analyst Eric Balchunas and the SEC's request for more documentation yesterday.</p>
                    
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}><TweetElement id="1792636523050906102"/></div>
                    
                    <p>In just over a day, ETH has soared more than 20%, rising from $3K~ to $3.6K+. BTC also surged past $70K, which is much faster than expected. Many other cryptocurrencies have also benefited from this funding pump.</p>
                    <p>If the Ethereum Spot ETF is indeed approved by the end of the month, we expect the value of ETH to soar by more than 50%. Now is a golden opportunity to buy ETH while prices are considerably low.</p>

                    <br />
                    <h2>Solana</h2>
                    <p>Solana remains resilient among all the cryptocurrencies and is likely to be the dark horse in this cycle. The Phantom Wallet has become the #3 app on the App Store, reflecting the general public's interest and confidence in the Solana ecosystem. Meme coins trading on Solana continue to trend highly among exchanges.</p>
                    <br />


                    <h2>US Economy</h2>
                    <p>With more US economic data expected to be released this week, likely to reveal more negative information, cryptocurrency performance is expected to strengthen further. Following these announcements, more rallies are anticipated throughout the week.</p>
                    <br />


                    <h2>Conclusion</h2>
                    <p>In the upcoming days, cryptocurrency prices will likely rise to levels seen before the dip in mid-March. It's a prime opportunity to invest before prices surge higher.</p>
                    <br />
                    <p>Tag: <SkillsButton color="purple" input="Cryptocurrency" /></p>
                </article> </div>
    );
};
 
export default AbruptMondayMay21;