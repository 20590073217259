import React from "react";
import SkillsButton from "./SkillsButton";
import ProgramButton from "./ProgramButton";


function Freelance() {
    return (
        <div>
            <div>
                <div className="grid-container work-details">
                    <div className="item1 col-xl-8 col-md-9"><h6>Freelance Engineer <span className="text-body-secondary">- TitoP</span></h6></div>
                    <div className="item2 col-xl-4 col-md-3"><h5 className="center year">2022 - Now</h5></div>
                    <div className="item3">
                        <p>Founded a web development and IT services startup based in Orange County, specializing in delivering customized digital solutions.</p>
                    </div>
                    <div className="item4 skillsButton  col-md-12">
                        <SkillsButton color="info" input="Javascript" />
                        <SkillsButton color="info" input="Typescript" />
                        <SkillsButton color="info" input="React.js" />
                        <SkillsButton color="info" input="Python" />
                        <SkillsButton color="yellow" input="PostgreSQL" />
                        <SkillsButton color="green" input="HTML" />
                        <SkillsButton color="green" input="CSS" />
                        <SkillsButton color="purple" input="Web3" />
                        <SkillsButton color="purple" input="Solana" />
                        <SkillsButton color="purple" input="Rust" />
                        <ProgramButton color="purple" input="Cryptoeconomics" />
                        <ProgramButton color="blue" input="Proxmox Virtualization" />
                        <ProgramButton color="blue" input="pfSense Firewall" />
                        <ProgramButton color="blue" input="TrueNAS" />
                        <ProgramButton color="beige"  input="VLAN" />
                        <ProgramButton color="navy" input="Windows Server" />
                        <ProgramButton color="navy" input="Linux Server" />
                        <ProgramButton color="pink" input="Adobe Illustrator" />
                        <ProgramButton color="pink" input="Adobe Photoshop" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Freelance;