import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
    BrowserRouter as Router,
    Routes,
    Route, useLocation
} from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';


import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Blog from "./Blog";
import Contact from "./Contact";
import Accessibility from "./Accessibility";
import Error404 from "./Misc_Parts/Error404";

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

function App() {
    const location = useLocation();
    const animationEffects = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.25 }
      };

    return (
        <div>
            <Header />
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route exact path="/" element={<motion.div  {...animationEffects}><Home /></motion.div>} />
                    <Route exact path="/about" element={<motion.div  {...animationEffects}><About /></motion.div>} />
                    <Route exact path="/services" element={<motion.div  {...animationEffects}><Services /></motion.div>} />
                    <Route exact path="/blog" element={<motion.div  {...animationEffects}><Blog /></motion.div>} />
                    <Route exact path="/contact" element={<motion.div  {...animationEffects}><Contact /></motion.div>} />
                    <Route exact path="/accessibility" element={<motion.div  {...animationEffects}><Accessibility /></motion.div>} />
                    <Route path="*" element={<motion.div  {...animationEffects}><Error404 /></motion.div>} />
                </Routes>
            </AnimatePresence>
            <Footer />
        </div>
    );

}

export default AppWrapper;
