import React from "react";
import BannerPost from "./Blog_Page/BannerPost";
import FeaturedPost from "./Blog_Page/FeaturedPost";
import BlogArea from "./Blog_Page/BlogArea";



function Blog() {
  return (
    <main className="container">
      <BannerPost />
      <FeaturedPost />
      <BlogArea />
    </main>
  );
}


export default Blog;
